/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-15 11:29:59
 * @Module: 个人实名认证
 */
 <template>
  <div>
    <el-card shadow="never">
      <hdqSection title="个人实名认证" more-text="" />
      <div class="content">
        <el-form ref="form" label-position="right" :model="form" label-width="100px" :rules="rules" size="small">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idNumber">
            <el-input v-model="form.idNumber" placeholder="请输入您的身份证号"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入您的手机号"></el-input>
          </el-form-item>
        </el-form>
        <div style="height:20px;"></div>
        <el-button type="primary" style="width:100%;" @click="submitForm" :loading="loading">确 定</el-button>
      </div>
    </el-card>
    <qrDialog ref="qrDialog">
      <span class="text">
        <span>请实名申请人用</span>
        <span>支付宝APP</span>
        <span>扫码并按照提示完成认证</span>
      </span>
    </qrDialog>
  </div>
</template>
 <script>
import qrDialog from "@/components/qrDialog.vue"
let js;
import { mapState } from "vuex";
export default {
  components: {
    qrDialog
  },
  data () {
    const phoneValidator = (_, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      }
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    const idCardValidator = (_, value, callback) => {
      if (!value) {
        return callback(new Error('身份证号不能为空'));
      }
      if (this.$utils.test.idCard(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的身份证号'));
      }
    }
    return {
      loading: false,
      form: {
        name: '',
        idNumber: '',
        phone: ''
      },
      rules: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        phone: [{ required: true, validator: phoneValidator, trigger: 'blur' }],
        idNumber: [
          { required: true, validator: idCardValidator, trigger: 'blur' }
        ],
      },

    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  mounted () {
    this.form.phone = this.user.phone;
  },
  methods: {
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postAccountAuth()
        } else {
          return false;
        }
      });
    },
    async postAccountAuth () {
      this.loading = true
      const { status, info, data } = await this.$api.postAccountAuth(this.form);
      if (status == 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$refs.qrDialog.show(data.authUrl)
        let js = setInterval(() => {
          this.$store.dispatch('user/getAccount', res => {
            if (res.name) {
              this.$refs.qrDialog.hidden()
              clearInterval(js);
              this.$router.go(-1)
              this.$store.dispatch('user/setUserInfo', res)
              this.$message({
                type: 'success',
                message: '实名认证成功'
              })
            }
          })
        }, 1000);
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
      this.loading = false
    },
    close () {
      js && clearInterval(js);
    }
  },
};
 </script>
 <style lang='scss' scoped>
.content {
  width: 400px;
  margin: 0 auto;
  padding-top: 20px;
}
.popper {
  padding: 0;
}
</style>